@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
    margin: 0;
}

a:link, a:hover, a:active, a:visited {
    color: #F8F8F8;
    text-decoration: none;
}

.App {
    text-align: center;
}

.actions-list {
    display: block;
    list-style: none;
}

.connectedUsers {
    display: block;
}

.conceil {
    text-shadow: 0 0 18px black;
    color: transparent;
    user-select: none;
}

.pokercard-container {
    display: grid;
}

.pokercard {
    display: grid;
    width: 8vw;
    height: 25vh;
    border-radius: 1.3em;
    margin: 0.3vw;
    cursor: pointer;
}

.pokercard:hover {
    transition: transform .2s;
    transform: scale(1.2);
}

.pokercard-overlay {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin: 0.3vw;
    border-radius: 1.3em;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: not-allowed;
}

.pokercard-image {
    display: block;
    margin: auto;
    max-width: 90%;
    max-height: 90%;
    user-select: none;
}

.pokercard, .pokercard-overlay {
    grid-area: 1/1;
}

.card-1 {
    background-color: #EDDCD2;
}

.card-2 {
    background-color: #FFF1E6;
}

.card-3 {
    background-color: #FDE2E4;
}

.card-5 {
    background-color: #F5A3C1;
}

.card-8 {
    background-color: #B1D2D1;
}

.card-13 {
    background-color: #DBE7E4;
}

.card-20 {
    background-color: #F0EFEB;
}

.card-40 {
    background-color: #D6E2E9;
}

.card-100 {
    background-color: #BCD4E6;
}

.card-what {
    background-color: #84B4D7;
}

.card-break {
    background-color: #5698C8;
}

.coffee {
    height: 50px;
    width: auto;
    border-radius: 5px;
    margin-top: auto;
    margin-bottom: auto;
}